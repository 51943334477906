import React from "react"

import './Cleaning.scss'


function Cleaning() {

  return(
    <div className="Cleaning flex column">

      <h2>Cleaning</h2>

    </div>
  )
}

export default Cleaning